<template>
  <v-container fluid grey lighten-3 pa-10>
    <!-- Summary section -->
    <v-alert
      v-model="showWarningMsg.alert"
      transition="scale-transition"
      :type="showWarningMsg.type"
      dismissible
      dense
      text
    >
      {{ showWarningMsg.msg }}
    </v-alert>

    <!-- Table section -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        :options.sync="options"
        :loading="dataTableLoading"
        :server-items-length="totalItems"
        :footer-props="{ 'items-per-page-options': [30, 50, 100, 200] }"
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white" height="80">
            <v-toolbar-title>Category</v-toolbar-title>
            <v-divider class="mx-4" inset vertical=""></v-divider>
            <v-text-field
              v-model="search"
              name="search"
              label="พิมพ์ ชื่อ"
              id="search"
              append-icon="search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              @click="$router.push('/category-create')"
              color="primary"
              dark
              class="mb-2"
            >
              <v-icon left>add</v-icon>
              <span>New Category</span>
            </v-btn>
          </v-toolbar>
        </template>


        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>
              <!-- aspect-ratio="1" หมายถึ อัตราส่วนรูปภาพ 1:1  -- lazy-src="http://www.google.com/image"  หมายถึงรุปจำลองก่อนโหดลรูปจริง-->
              <v-img
                v-if="item.image"
                :src="item.image | imageUrl"
                max-height="50"
                max-width="50"
                contain
              />
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.tag }}</td>
            <td><v-switch v-model="item.active" color="success" label="active" hide-details @click="switchActive(item)"></v-switch></td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)"> edit</v-icon>
              <v-icon class="mr-2" @click="deleteItem(item)"> delete</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="confirmDeleteDlg" max-width="290">
        <v-card>
          <v-card-title class="headline">Confirm Delete</v-card-title>
          <v-card-text class="body">
            ต้องการลบรายการนี้ใช่หรือไม่?
            <h3>{{selectedItem}}</h3>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
            <v-btn color="error" text @click="confirmDelete">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>

import api from "@/services/api";
import axios from "axios";
import _ from "lodash";

export default {
  name: "Category",
  data() {
    return {
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: "Image", value: "image" },
        { text: "Name", value: "name" },
        { text: "Tag", value: "tag" },
        { text: "Active", value: "active" },
        { text: "Action", value: "action" }
      ],
      search: "",
      selectedItemId: "",
      confirmDeleteDlg: false, // false เท่ากับไม่ให้แสดง
      selectedItem: '',
      showWarningMsg: {
        alert: false,
        type: "success",
        msg: "ลบสำเร็จ"
      },
      dataTableLoading: true,
      totalItems: 0,
      options: {} // for dataTable
    };
  },
  components: {
    // register ตัว components ที่เราทำการ import เข้ามาให้สามารถใช้งานได้
    
  },
  mounted() {
    console.log("this category components is running");
    //this.loadData();
  },
  methods: {
    searchItems: _.debounce(function() {
      // _.debounce ใน lodash ต่างจาก settimeout อย่างไร   
      // 1. debounce จะ return ค่าออกมาเป็น function  สว่น   settimeout จะ return เป็น id 
      // 2. debounce ตัวอย่างการใช้เช่น เวลาพิมพ์ search ข้อมูล function ใน debounce จะทำงานก็ต่อเมื่อเราได้หยุดพิมพ์ในเวลา 1.5 วิ แต่ถ้าในเวลา 1.5 วิ มีการพิมพ์ต่อ debounce ก็จะนับถอยหลังใหม่
      //     ซึ่งจะทำให้ การยิง request ไปที่ server ลดจำนวนการยิงลงจนกว่าเราจะพิมพ์ข้อความที่ต้องการค้นหาเสร็จ และไม่มีการพิมพ์ต่อเนื่องใน 1.5 วินั้นเอง
      this.loadData();
    }, 1000),
    loadData() {
      this.getDataFromApi()
        .then(data => {
          this.mDataArray = data.rows;
          this.totalItems = data.totalItems;
        })
        .catch(error => {
          console.log("myerror :" + error);
        });
    },
    getDataFromApi() {
      this.dataTableLoading = true;
      return new Promise((resolve, reject) => {
        const { page, itemsPerPage } = this.options;
        api.getCategories(page, itemsPerPage, this.search).then(result => {
          let rows = result.data.rows;
          let totalItems = result.data.count;
          setTimeout(() => {
            this.dataTableLoading = false;
            resolve({ rows, totalItems });
          }, 600);
        });
      });
    },
    editItem(item) {
      this.$router.push(`/category-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedItemId = item.id;
      this.selectedItem = `รหัส: ${item.id} ${item.name}`;
      this.confirmDeleteDlg = true;
    },
    switchActive: _.debounce(async function(item) {
        this.dataTableLoading = true;
        let {id, active} = item;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("active", active ? 1 : 0);
        let result = await api.updateCategoryToActive(formData);
        this.dataTableLoading = false;

    }, 700),
    async confirmDelete() {
      let result = await api.deleteCategory(this.selectedItemId);
      if (result.data.result == "ok") {
        this.showWarningMsg = {
          alert: true,
          type: "success",
          msg: "ลบข้อมูลสำเร็จ"
        };
      } else {
        this.showWarningMsg = {
          alert: true,
          type: "error",
          msg: "ลบข้อมูลไม่สำเร็จ"
        };
      }

      this.confirmDeleteDlg = false; // ซ้อนการแจ้งเตือน dialog
      this.loadData();
    }
  },
  watch: {
    showWarningMsg() {
      setTimeout(() => {
        this.showWarningMsg.alert = false;
      }, 3000);
    },
    options: {
      //เมื่อค่า options ที่เรา sync กับ datable เปลี่ยน method นี้จะทำงานทันที  watch นี้ ชื่อ method ต้องตรงกันกับตัวแปรที่ประกาศข้างบน
      handler(newValue, oldValue) {
        //console.log(this.options.page, this.options.itemsPerPage)
        this.loadData();
      },
      deep: true
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.searchItems();
        }
      }
    }
  },
  beforeDestroy: function() {
    delete this.mDataArray;
    delete this.options;
  }
};
</script>

<style></style>
